.fila {
  border: 3px solid blue;
  background-color: lightblue;
  margin-bottom: 15px;
  /* height: 180px; */
}

.columna {
  background-color: lightgreen;
  border: 2px solid green;
  padding: 10px;
  height: 30px;
}

.ri {
  max-width: 100%;
  height: auto;
}
